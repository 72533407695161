import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);

  // Charge OpenPay script
  useEffect(() => {
    if (!document.getElementById('openpay-script')) {
      const script = document.createElement('script');
      script.id = 'openpay-script';
      script.src = `https://openpay.s3.amazonaws.com/openpay.v1.js`;
      document.head.append(script);
    }
  }, []);

  // Charge OpenPay script
  useEffect(() => {
    if (!document.getElementById('openpay-data-script')) {
      const script = document.createElement('script');
      script.id = 'openpay-data-script';
      script.src = `https://openpay.s3.amazonaws.com/openpay-data.v1.js`;
      document.head.append(script);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {routing}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
