import { 
  CARD, CUSTOMER_INFORMATION, AMOUNT, REFERENCE, PLAN, RECURRENCE,
  FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE,
  FETCH_DATA_DETAIL_SUCCESS, FETCH_REQUEST_DONE, PAYMENT, FETCH_REQUEST_PENDING,
  FETCH_REQUEST_INIT_CHALLENGE, FETCH_REQUEST_ENABLE_CHALLENGE
} from '../constants';

const INIT_STATE = {
  loading: false,
  title: "",
  authorization: null,
  logo: null,
  amount: 0.00,
  reference: "",
  planName: "",
  recurrence: "",
  currency: "484",
  customerInformation: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      phone1: "",
      city: "",
      address1: "",
      postalCode: "",
      state: "",
      country: "",
      ip: ""
  },
  cardData: {
      cardNumber: "",
      cvv: "",
      cardholderName: "",
      expirationYear: "",
      expirationMonth: ""
  },
  iframe: null,
  collectFrame: null,
  enrollmentFrame: null,
  error: null
};

const PaymentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CARD:
      return {
        ...state,
        cardData: action.payload,
      };
    case CUSTOMER_INFORMATION:
      return {
        ...state,
        customerInformation: action.payload,
      };
    case AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case REFERENCE:
      return {
        ...state,
        reference: action.payload,
      };
    case PLAN:
      return {
        ...state,
        planName: action.payload,
      };
    case RECURRENCE:
      return {
        ...state,
        recurrence: action.payload,
      };
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        iframe: null,
        error: null
      };
    case FETCH_REQUEST_INIT_CHALLENGE:
      return {
        ...state,
        enrollmentFrame: null,
        collectFrame: action.payload
      };
    case FETCH_REQUEST_ENABLE_CHALLENGE:
      return {
        ...state,
        loading: false,
        collectFrame: null,
        enrollmentFrame: action.payload
      };
    case FETCH_REQUEST_PENDING:
      return {
        ...state,
        loading: false,
        iframe: action.payload
      };
    case FETCH_REQUEST_DONE:
      return {
        ...state,
        loading: false,
        error: null
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        amount: action.payload.amount,
        reference: `${action.payload.external_reference}-${action.payload.sku}`,
        title: action.payload.title,
        image: action.payload.image,
        authorization: action.payload.authorization
      };
    case FETCH_DATA_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        authorization: action.payload.authorization,
        logo: action.payload.logo
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: { message: action.payload }
      };
    case PAYMENT:
      console.log('state ', state);
      return state;
    default:
      return state;
  }
};

export default PaymentReducer;
